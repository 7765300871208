<template>
  <div id="app">
    <div class="container">
      <router-view></router-view>
      <lg-preview></lg-preview>
    </div>
  </div>

</template>
 
<script>
import useaddRoute from "./utils/loadingRouter";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {},
  data() {
    return {
      
    };
  },
  created() {
    useaddRoute();
  },
  mounted() {
      var restaurantName=this.$route.params.id;

       // 根据不同路由跳转不同页面
      if (restaurantName != undefined) {
      if (this.isMobile()) {
        this.$router.replace('/mobile/takeaway/' + restaurantName)
      } else {
        this.$router.replace('/takeaway/' + restaurantName)
      }
      
     
    }
  },
 
  computed: {
    ...mapGetters(["updateNav", "updateStore"]),
  },
 
  methods: {
     isMobile () {
      if (typeof window !== 'undefined' && window.innerWidth <= 768) {
                return true;
            } else {
                return false;
            }
    },
    changeFavicon(src) {
    
    let link = document.querySelector("link[rel='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = src;
  }
  },
};
</script>

<style lang="scss">

</style>