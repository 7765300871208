export default {
    cartList:"Cart List",
    editCart:"Edit Cart",
    back:"Back",
    continue:"Continue",
    finished:"Finished",
    total:"Total",
    deliveryAnTime:"Delivery method and time",
    pickUp:"Pick up",
    pickUpRemark:"Go to the restaurant and pick it up yourself",
    delivery:"Delivery",
    deliveryRemark:"Delivered to your address",
    buyerInfo:"Buyer Information",
    name:"Name",
    telephone:"Telephone",
    eamil:"Mail",
    postNumber:"Post number",
    postFeeTip:"Delivery fee",
    postBy:"By",
    address:"Address",
    adult:"Adult",
    child:"Child",
    special:"Special",
    subTotal:"Sub total",
    nameNotNull:"Please enter your name",
    phoneNotNull:"Please enter a phone number",
    mailNotNull:"Please enter an email",
    phoneMailNotNull:"Either the phone number or email should be filled in",
    mailEror:"Email format error",
    postnumberNotNull:"Please enter the postal code",
    postByNotNull:"Please enter a postal address",
    addresssNotNull:"Please enter detailed address",
    voksneNotNull:"Please enter the number of people",
    amountNotNull:"Order amount is 0, submission not allowed",
    orderSuccess:"Order submitted successfully",
    requestError:"Request address error, please check the access address",
    sure:"Sure",
    error:"Error",
    confirmOrder:"Confirm order",
    submit:"Submit",
    deliveryTime:"Delivery time",
    ordreSuccess:"Ordre success",
    sendSuccess:"Orders have sent success!",
    sendSayTip:"Thank you for ordering food with us We will call you as soon as possible to confirm your order",
    orderDetial:"View order",
    home:"Home",
    noOrder:"No order information exists. Please check after placing the order",
    login:"Login",
    passwordLogin:"Password login",
    smsLogin:"Sms login",
    register:"Register",
    forget:"Forget password ",
    send:"Send",
    videCode:"CAPTCHA",
    passwordNotNull:"Password cannot be empty",
    registered:"The user has registered, please log in",
    gotoOrder:"Cart list",
    emptyCartList:"Your basket is empty. If in doubt, start with one of our tasty menus",
    shopCart:"Your shopping cart",
    shopName:"Dish Name",
    num:"Num",
    delete:"Delete",
    price:"Unit-price",
    coupon:"Coupon",
    phoneLenLimit:"The telephone number must be 8 digits",
    timeNotNull:"Please select the order time",
    restaurant:"Restaurant",
    leveringOrAfhentning:"Delivery/Self pickup",
    gotoBox:"Go to order",
    cart:"Cart list",
    pendingOrderInfo:"Pending order information",
    orderingInfo:"Ordering Information",
    noRecentOrders:"No recent orders exist",
    choice:"Choice",
    close:"Close",
    restaurantName:"Restaurant",
    yourName:"Your name",
    numberDiners:"Number of diners",
    bookTime:"Scheduled time",
    bestilling:"Booking",
    nullStore:"No store information exists. If the domain name is not bound to a store, please go to the backend to bind it",
    bookTimeNull:"Please select the complete booking time", 
    selectHours:"Select hours",
    selectMinutes:"Select minutes",
    informationNull:"Required information not fully filled in",
    successfullyBooked:"Successfully booked",
    reservationFailed:"Reservation failed",
    nullCustomer:"This name and phone number do not exist, please check the information!",
    unsubscribe:"Unsubscribe Newsletter",
    subscribe:"Subscribe to Newsletter",
    find:"Find",
    os:"US",
    restaurant:"Restaurant",
    openingHours:"Business hours",
    Wednesday:"Wednesday",
    afmeld:"Unsubscribe",
    paymentMethod:"Payment method",
    payment:"Payment",
    paymentRemark:"When you click payment, you will be sent to our payment page where you will finish the payment.",
    restaurantRemark:"Pay in the restaurant",
    deliveryOptionTitle:"Choose food delivery options",
    needPaytype:"Please select a payment method",
    submitSuccess:"Submitted successfully",
}
