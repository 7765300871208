 
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter)
 const isFirstLogin=true;
// 引入静态路由
import { staticRouter } from "@/utils/data";
const routes = staticRouter;
 
const router = new VueRouter({
    mode: 'history',
    routes
})

    
export default router