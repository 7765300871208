export default {
    cartList:"Indkøbskurvsliste",
    editCart:"Redigér kurv",
    continue:"Fortsæt",
    back:"Opdater",
    finished:"Færdig",
    total:"Total",
    deliveryAnTime:"Tid og sted",
    pickUp:"Hent din sushi selv",
    pickUpRemark:"Afhentes i vores restaurant på",
    delivery:"Få din sushi leveret til døren",
    deliveryRemark:"Sushien skal leveres",
    buyerInfo:"Køberinformation",
    name:"Navn",
    telephone:"Kontakt telefon",
    eamil:"E-mail",
    postNumber:"Postnr.",
    postFeeTip:"Pris for udbringning", 
    postBy:"By",
    address:"Address",
    adult:"Antal voksne", 
    child:"Antal børn",
    special:"Specielle ønsker",
    subTotal:"Subtotal",
    nameNotNull:"Indtast venligst dit navn",
    phoneNotNull:"Indtast venligst et telefonnummer",
    mailNotNull:"Indtast venligst en e-mail",
    phoneMailNotNull:"Enten telefonnummer eller e-mail skal udfyldes",
    mailEror:"Fejl i e-mail- format",
    postnumberNotNull:"Indtast venligst postnummeret",
    postByNotNull:"Indtast venligst en postadresse",
    addresssNotNull:"Indtast venligst detaljeret adresse",
    voksneNotNull:"Indtast antallet af personer",
    amountNotNull:"Ordrebeløbet er 0, indsendelse er ikke tilladt",
    orderSuccess:"Bestilt med succes.",
    requestError:"Anmod adressefejl, tjek venligst adgangsadressen",
    sure:"Bekræft",
    error:"Fejl",
    confirmOrder:"Bekræft rækkefølge",
    submit:"Bestil",
    deliveryTime:"Leveringstid",
    ordreSuccess:"Ordre success",
    sendSuccess:"Ordre sendt med succes!",
    sendSayTip:"Tak fordi du bestilte vores mad. Vi ringer til dig hurtigst muligt for at bekræfte din ordre",
    orderDetial:"Vis ordrer",
    home:"Hjemmeside",
    noOrder:"Ingen bestillingsoplysninger gemt, tjek venligst efter bestilling",
    login:"Log ind",
    passwordLogin:"Password login",
    smsLogin:"SMS- login",
    register:"Register",
    forget:"Glemt adgangskode",
    send:"Send",
    videCode:"Verifikationskode",
    passwordNotNull:"Adgangskode kan ikke være tom",
    registered:"Brugeren har registreret sig, log venligst ind",
    gotoOrder:"Gå til bestilling",
    emptyCartList:"Din kurv er tom. Hvis du er i tvivl, så start med en af vores velsmagende menuer.",
    shopCart:"Din indkøbskurv",
    shopName:"Beskrivelse",
    num:"Pris",
    delete:"Slet",
    price:"Antal",
    coupon:"Kupon",
    phoneLenLimit:"Telefonnummeret skal være 8 cifre",
    timeNotNull:"Vælg venligst bestillingstid",
    restaurant:"Restaurant",
    leveringOrAfhentning:"Levering/selvstændig afhentning",
    gotoBox:"Ga til kassen",
    cart:"indkøbskurv",
    pendingOrderInfo:"Pending order information",
    orderingInfo:"Ordreinformation",
    noRecentOrders:"Der findes ingen nylige ordrer",
    choice:"Markeret",
    close:"luk",
    restaurantName:"Vælg Restaurant",
    yourName:"Dit navn",
    numberDiners:"Antal spisende",
    bookTime:"Dato  ",
    bestilling:"Bestilling",
    nullStore:"Não existe informação de armazenamento. Se o nome de domínio não estiver vinculado a um armazenamento, por favor vá para a infra-estrutura para vinculá-lo",
    bookTimeNull:"Vælg venligst den komplette reservationstid", 
    selectHours:"TidTime",
    selectMinutes:"Minut",
    informationNull:"Krævede oplysninger ikke fuldt udfyldt",
    successfullyBooked:"Bestillet med succes",
    reservationFailed:"Reservation mislykkedes",
    nullCustomer:"Dette navn og telefonnummer findes ikke, tjek venligst oplysningerne!",
    unsubscribe:"AfMeld Nyhedsbrev",
    subscribe:"Tilmeld dig nyhedsbrev",
    find:"Find",
    os:"OS",
    restaurant:"RESTAURANT",
    openingHours:"ÁBNINGSTIDER",
    afmeld:"Afmeld",
    paymentMethod:"Betalingsmetode",
    payment:"Betaling",
    paymentRemark:"Når du trykker på Gå til betaling, bliver du sendt til vores betalingsside, hvor du afslutter betalingen.",
    restaurantRemark:"Restaurant",
    payment:"Betal nu",
    paymentRemark:"Når du trykker på Gå til betaling, bliver du sendt til vores betalingsside, hvor du afslutter betalingen.",
    restaurantRemark:"Betal i restaurant",
    deliveryOptionTitle:"Vælg venligst leveringsmetode",
    needPaytype:"Vælg venligst en betalingsmetode",
    submitSuccess:"Indsendt med succes",
    }
    