const state = {
 
    isLogin:false,
    routeArr:[],   
}
 
// mutations操作数据
const mutations = {
 
    CHANGESTORE(state,data){
        state.isLogin = data;
    },
 
    HEARDER(state,data){
        state.routeArr = data;
    }
  
}
 
const actions = {
 
    updateStoreChange(contenxt,state){
        contenxt.commit('CHANGESTORE',state)
    },
 
    Nav({commit},state){
        commit('HEARDER',state)
    }
}
 
// 导出
export default {
  state,
  mutations,
  actions
}