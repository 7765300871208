import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elementDkLocale from 'element-ui/lib/locale/lang/da'
import elementPtLocale from 'element-ui/lib/locale/lang/pt'
import locale from 'element-ui/lib/locale'
import enLocale from './lang/en'
import zhLocale from './lang/zh'
import dkLocale from './lang/dk'
import ptLocale from './lang/pt'
Vue.use(VueI18n)

// 引入本地包
const messages = {
  zh: {
    ...zhLocale,
    ...elementZhLocale
  },
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  dk: {
    ...dkLocale,
    ...elementDkLocale
  },
  pt: {
    ...ptLocale,
    ...elementPtLocale
  }
}

// 创建国际化实例
const i18n = new VueI18n({
  locale: window.localStorage.getItem('i18n') || 'en',
  messages
})

locale.i18n((key, value) => i18n.t(key, value))

export default i18n