import { staticRouter, dynamicRouter } from "./data";
import router from "../router/index";
import store from "../store/index";
 
const routes = staticRouter;
 
export default function useaddRoute() {
    if (store.state.app.isLogin) {
        // 模拟发送请求 放回后端路由数据 
        setTimeout(() => {
            let data = routesData(dynamicRouter)
            store.dispatch('Nav', data)
            // 循环添加  addRoute (vue-router 建议使用的方法)
            data.forEach(item => {
                router.addRoute(item)
            });
        }, 2000);
    } else {
        store.dispatch('Nav', staticRouter)
    }
 
 
}
 
// 追加动态路由
function routesData(result) {
    result.forEach(item => {
        routes.push({
            path: item.path,
            name: item.name,
            meta: item.meta,
            // 注意追加路径
            // component: () => import(`../views/dynamicRoutes/`),
            component: () => import(`../views//${item.component}`),
        })
    })
    return routes
}